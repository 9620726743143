<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        
        <div class="row mb-2">
            <div class="col">
                <h1 class="text-primary font-weight-bolder  ml-1" >MES FORMATIONS DDA</h1>
            </div>
            <div class="col-auto">
              <b-button
                block
                variant="outline-primary"
                @click="backStep"
              >
              <feather-icon 
               icon="ArrowLeftIcon"
               class="mr-50"
              />
                <span>REVENIR À LA LISTE</span>
              </b-button>
            </div>
        </div>

        <section>
            <b-row>
                <b-col lg="12">
                    <b-card >
                        <b-card-body>
                            <b-row  class="align-items-center mb-2">
                                <b-col class="col-auto">
                                    <h4 class="title-custom-wizard">
                                        Vous avez gagné une de nos formations DDA ? Félicitations !
                                    </h4>
                                </b-col>
                                <b-col class="col-auto">
                                    <b-button @click="informationRedirect" variant="primary" >
                                        <span>Accéder à ma formation</span>
                                    </b-button>
                                </b-col>
                            </b-row>
                            <b-row class="mb-2">
                                <b-col class="col-auto">
                                    <p>Comment gagner une formation DDA ? C’est très simple, réalisez 3 contrats depuis Bubble-In ! </p>
                                    <p>Voici les parcours de formations proposées :</p>
                                </b-col>
                            </b-row>
                            <b-container align-h="center" align-v="center">
                                <b-row>
                                    <div>
                                        <b-card-group class="bg-light px-2 pt-2 pb-3" deck>
                                            <b-card v-for="parcour in parcours_formations" :key="parcour.id" :title="parcour.title" :img-src="require('@/assets/images/formation_dda/' + parcour.imgSrc + '.png')" img-alt="Image" img-top>
                                                <b-collapse :id="'collapse-text-' + parcour.id" class="my-2">
                                                    <div  v-html="parcour.description"></div>
                                                </b-collapse>
                                                <b-button v-b-toggle="'collapse-text-' + parcour.id" block variant="primary"><span class="text-capitalize">Lire la fiche</span></b-button>
                                            </b-card>
                                        </b-card-group>
                                    </div>
                                </b-row>
                            </b-container>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>


        </section>
    </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import {required} from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormInvalidFeedback,BImg,BOverlay,BRow,BCol,BTabs, BTab,BCardTitle, BCardText,BCard,BCardBody,BCardHeader,BButton,BFormRadioGroup,BFormGroup,BFormInput,BCardGroup,BContainer,BCollapse,VBToggle } from 'bootstrap-vue'
export default {
    components:{
        BFormInvalidFeedback,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BCardCode,
        BCardText,
        BCardHeader,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BCardTitle,
        BButton,
        BFormRadioGroup,
        BFormGroup,
        BFormInput,
        ToastificationContent,
        BCardGroup,
        BContainer,
        BCollapse
    },
    data() {
        return {
           
            showLoading:false,
            currentUser: JSON.parse(localStorage.getItem('userData')),
            parcours_formations:[
                {
                    id:1,
                    name:"parcours_reglementaire",
                    title:"E-learning Parcours réglementation",
                    description:'<p>Ce parcours a été conçu pour aider à naviguer à travers les réglementations essentielles qui régissent le courtage en assurance.</p><p>Au cours de ce parcours, vous explorerez les sujets suivants :</p><ul><li>L\'autorégulation du courtage ou l\'accompagnement des professionnels du courtage (1 heure)</li><li>Protection de la clientèle (1 heure)</li><li>Obligation d\'information et devoir de conseil sous DDA (2 heures)</li><li>RGPD - Comprendre et savoir interpréter ses exigences dans l\'assurance (2 heures)</li><li>Traitement des réclamations clients et médiation (45min)</li><li>PLFSS 2023 : Quels impacts sur les assureurs complémentaires santé et prévoyance (1 heure)</li><li>Protection sociale des travailleurs non-salariés (TNS) (1h30)</li><li>Sensibilisation à la lutte contre la fraude (2 heures)</li><li>Sensibilisation à la LCB-FT (2 heures)</li><li>Négociation commerciale : mieux vendre ses différences, son expertise et son professionnalisme (4 heures)</li></ul>',
                    imgSrc:"formation_Reglementation"
                },
                {
                    id:2,

                    name:"parcours_assurance_personne",
                    title:"E-learning Parcours assurance de personne",
                    description:'<p>Ce parcours a été spécialement conçu pour fournir les connaissances et compétences essentielles dans le domaine des assurances de personnes, afin de renforcer l\'expertise et le professionnalisme.</p><p>Au cours de ce parcours, vous explorerez les sujets suivants :</p><ul><li>Protection de la clientèle (1 heure)</li><li>Traitement des réclamations clients et médiation (45min)</li><li>PLFSS 2023 : Quels impacts sur les assureurs complémentaires santé et prévoyance (1 heure)</li><li>La prévoyance collective (2 heures)</li><li>La loi Pacte et la Réforme des retraites (2 heures)</li><li>Fiscalité et assurance vie (2 heures)</li><li>Actualités jurisprudentielles de l\'assurance vie (1 heure)</li><li>Impacts de la Loi Lemoine sur l\&#39;Assurance emprunteur (1 heure)</li><li>Négociation commerciale : mieux vendre ses différences, son expertise et son professionnalisme (4 heures)</li></ul>',
                    imgSrc:"formation_Personne"


                },
                {
                    id:3,

                    name:"parcours_iard",
                    title:"E-learning Parcours IARD",
                    description:'<p>Ce parcours a été spécialement conçu pour fournir les connaissances et compétences essentielles dans le domaine des assurances de biens.</p><p>Au cours de ce parcours, vous explorerez les sujets suivants :</p><ul><li>RGPD - Comprendre et savoir interpréter ses exigences dans l\'assurance (2 heures)</li><li>Protection de la clientèle (1 heure)</li><li>Traitement des réclamations clients et médiation (45min)</li><li>Les fondamentaux de l\'assurance IARD (2 heures)</li><li>Responsabilité civile et civile immobilière (2 heures)</li><li>Négociation commerciale : mieux vendre ses différences, son expertise et son professionnalisme (4 heures)</li><li>Négociation commerciale : structurer et préparer ses négociations face à des clients aguerris (4 heures)</li></ul>',
                    imgSrc:"formation_IARD"

                }
            ]
        }
    }, 
    directives: {
    'b-toggle': VBToggle,
  },
    methods: {
        backStep(){
            console.log(this.formationPage)

                this.showLoading=true
                this.$store.commit("setStep", {
                    step: 'searchCatalogue',
                });
                this.$store.commit("setTab", {tab: 'assureur'});
                this.showLoading=false

        },
        
        informationRedirect(){
            window.open("http://formation.bubble-in.com/", '_blank');
        },
        
    },
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
}
.card img{
    border-radius: 1rem;
}
.card .card-body{
    padding: 0;
}
.card {
    padding:1.5rem;
}
.card .card-title{
    margin-top:1.53rem;
}
</style>