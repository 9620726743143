<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        <div class="row mb-2">
            <div class="col">
                <b-col class="md-12">
                    <div class="d-flex">
                        <div class="position-relative mr-3">
                            <img src="../../../public/Anne.png"  alt="logo" id="logo" height="120" />
                        
                        </div>
                    </div>
                </b-col>
            </div>
            <div class="col-auto">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="outline-primary"
                @click="backStep"
              > <feather-icon 
               icon="ArrowLeftIcon"
               class="mr-50"
              />
              
                REVENIR À LA LISTE
              </b-button>
            </div>
        </div>
        <section>
            <b-row>
                <b-col lg="9">
                    <b-card>
                        <b-card-header class="flex-column align-items-start pb-1">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FeatherIcon" size="20" />DESCRIPTION</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <div class="text-all-content text-justify">
                                <div style="font-size:14,5px"> 
                                    <div class="content-text">Maître Anne-Julie Giraud, avocate au barreau de Paris, est spécialisée en droit des affaires & professions règlementées et ainsi qu’en conformité RGPD.</div> 
                                    <div class="content-text">Le Cabinet AJ Giraud peut vous conseiller, vous assister et vous représenter devant la plupart des juridictions.</div>
                                 </div>
                                
                                <h4 style="margin:20px 0px">ETHIQUE DES AFFAIRES – PROFESSIONS RÈGLEMENTÉES</h4>
                                <div class="content-text">Le cabinet conseille ses clients dans la mise en place de process et gouvernance adaptés à leur structure.
                                Nous aidons les entreprises à maîtriser leurs obligations règlementaires tout en s’assurant du développement commercial et de la pérennité de leurs activités.
                                Notre objectif est de définir et prévenir les risques afin de sécuriser l’entreprise et ses actifs.</div>
                                <div class="content-text">Nous intervenons notamment dans les domaines suivants :</div>
                                <div class="content-text" style="margin:20px 0px"><feather-icon icon="CircleIcon" class="icon mr-50 ml-2"/>Dispositifs anti-corruption et anti-blanchiment, en interne et auprès des autorités de contrôle pour l’obtention d’autorisations ou licences</div>
                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/>Prévention de la corruption (directives européennes et transposition en droit interne, loi Sapin II) et du blanchiment (Tracfin)</div>
                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/>Conformité AML</div>                           
                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/>Veille juridique et réglementaire</div>
                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/>Cartographie des risques</div> 
                                <div class="content-text" style="margin-top:20px">Notre cabinet accompagne les acteurs de l’assurance et de la distribution de produits bancaires et financiers dans leur mise en conformité de leurs activités règlementées.</div>
                                <div class="content-text">Nous intervenons tant en conseil qu’en contentieux dans les domaines suivants :</div> 

                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50 "/>Assistance dans la mise en conformité de l’entreprise avec les règles relatives à la distribution de tels produits (protection de la clientèle, obligations d’information et de conseil, vente à distance, démarchage, lutte anti-blanchiment, mise en œuvre de la Directive sur la Distribution d’Assurances, Directive anti-blanchiment, protection des données personnelles, RGPD).
Entrée en relations d’affaires, suivis et fin de contrats avec les acteurs de l’assurance (assureurs, mutuelles…) dans le respect de la règlementation et des règles du
courtage.)</div> 
                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/>Préparation et assistance aux contrôles de l’Autorité de tutelle (ACPR- AMF) ou tout autre autorité administrative ou judiciaire</div> 
                                <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/>Formation à destination des professionnels de l’intermédiation et de la distribution de produits d’assurances, bancaires et financiers</div>                           
                                <h4 style="margin:20px 0px">RGPD</h4>
                                <div class="content-text">Depuis le 25 mai 2018, date de l’entrée en vigueur du Règlement Général sur la Protection des Données (RGPD), les entreprises sont confrontées à de nouvelles obligations lors du traitement des données personnelles de résidents de l’Union Européenne (collecte, transfert, utilisation, réception …).
Le cabinet de Me Anne Julie Giraud, cabinets d’avocats RGPD à Paris, assiste les entreprises dans la mise en conformité de leurs activités à la règlementation relative à la protection des données personnelles (Lois informatiques et libertés, RGPD….).
Inscrite comme déléguée à la protection des données personnelles auprès de l’Ordre des avocats du Barreau de Paris, Me Anne-Julie Giraud accompagne ses clients dans le cadre de prestations de délégué à la protection des données externalisé (DPO).</div>



                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col lg="3">
                    <div>
                        <b-card>
                            <b-card-header class="d-flex justify-content-start align-items-center pb-1">
                                <b-card-title>
                                    <h4 class="title-custom-wizard">
                                        <feather-icon class="box-icon-text" icon="UsersIcon" size="20" />INTERLOCUTEURS</h4>
                                </b-card-title>
                            </b-card-header>
                            <b-card-body>
                                <!-- user suggestion  -->
                                <div class="mb-2">
                                    <b-avatar  class="mr-50 mb-1" size="40" />
                                    <div class="user-page-info">
                                        <h6 class="mb-0">
                                            Anne-Julie GIRAUD
                                        </h6>
                                        <small class="text-muted d-block">
                                            <tr><td>Portable </td><td style="padding: 0 10px;">:</td><td>06 10 53 45 79</td></tr>
                                            <tr><td>Fixe </td><td style="padding: 0 10px;">:</td><td>01 71 60 90 58</td></tr>
                                            <tr><td>Email </td><td style="padding: 0 10px;">:</td>
                                            <td>
                                                <a :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="showEmail('ajgiraud@avocat-giraud.com')">
                                                    ajgiraud@avocat-giraud.com
                                                </a>    
                                            </td>
                                            </tr>
                                              <tr>
                                                <td>Adresse</td>
                                                <td style="padding: 0 10px">:</td>
                                                <td>
                                                Avocat au Barreau de Paris<br>
                                                 15 avenue Raymond Poincaré<br> 
                                                 75116 Paris
                                                </td>
                                            </tr>
                                         </small>
                                    </div>
                                </div>
                            </b-card-body>
                       
                        </b-card>
                    </div>
                </b-col>
          
            </b-row>
        </section>
    </div>

</template>

<script>
import {
    mapGetters
} from "vuex";
import Interlocuteurs from '@/views/assureur/animation-commerciale/assureur/interlocuteurs/Interlocuteurs.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import {
    quillEditor
} from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import InterlocuteursData from "@/shared/constants/interlocuteurs"
import {
    BImg,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BAvatar,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormCheckboxGroup,
    BFormTextarea
} from 'bootstrap-vue'
import {
    required, email
} from '@validations'
export default {
    components: {
        Ripple,
        BForm,
        BFormGroup,
        BFormInput,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BModal,
        BAvatar,
        BFormFile,
        BCardHeader,
        BCardTitle,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        BCardCode,
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        // ToastificationContent,
        BFormCheckbox,
        BListGroup,
        BListGroupItem,
        BCardText,
        BLink,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BButton,
        BFormTextarea,
        BMedia,
        BMediaAside,
        BFormCheckboxGroup,
        BMediaBody,
        Interlocuteurs
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    mixins: [heightTransition],
    created: function () {
        this.checkSyncBoiteEmail()
        // this.getAllProduits()
        // this.$store.dispatch('getAllServices')
    },
    data() {
        return {
            isBoiteSync: false,
            showLoading:false,
            produitType:null,
            assureur: null,
            interlocuteurs: [],
            logo: null,
            edit: false,
            base64regex: /^data:image\/([a-zA-Z]*);base64,([^\"]*)?$/,
            required,
            email
        }
    },
    props: {
        isCatalogue: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    computed: {
        ...mapGetters({services : "getServices", objectAssurance : "getObjet"}),
    },
    methods: {
        checkSyncBoiteEmail() {
            this.isBoitEmailSync().then(response => {
                if(response)
                this.isBoiteSync = true
                else this.isBoiteSync = false
            })
        },
        showEmail (email, copierCarbon = null) {
            if(this.isBoiteSync) {
                this.$router.push({
                    name: 'messageries',
                    params: {
                    operation: 'send',
                    email,
                    copierCarbon
                    }
                })
            }
        },
        backStep(){
            this.showLoading = true
            this.$store.commit("setFromList",false);
            this.$store.commit("setStep", {
                step: 'searchCatalogue',
            });
            this.showLoading = false
        },
      
    
      
    }
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
    margin-bottom: 5px;
}

.box-icon-text{
    margin-top: -4px;
    margin-right: 5px;
}
.edit-img {
    position: absolute;
    bottom: -5px;
    right: -15px;
    z-index: 6;
}
.content-text{
    margin: 10px 0;
}
.point{
    font-size: 50px;
}
.icon{
    width: 6px;
}
.text-all-content{
    font-size: 13.5px;
}
</style>


