<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        <div class="row">
            <div class="col">
                <h1 class="text-primary font-weight-bolder  ml-1" >{{risque.label}}</h1>
            </div>
            <div class="col-auto">
              <b-button
                block
                variant="outline-primary"
                @click="backStep"
              >
              <feather-icon 
               icon="ArrowLeftIcon"
               class="mr-50"
              />
                {{ fromList ? 'REVENIR À LA LISTE' : 'REVENIR VERS L\'ASSUREUR' }}
              </b-button>
            </div>
        </div>
        <div>
            <ul role="tablist" class="nav nav-tabs nav-assurances my-2" v-if="fromList" >
                <li role="presentation" class="nav-item" v-for="(produit, index) in risque.produits" :key="index" v-show="canShowProduit(produit.id, null)">
                    <!-- <a href="#" class="nav-link" :class="indexAssureur==index ? 'active': ''" @click="indexAssureur=index">
                        {{ produit.assureur.nom_com_assureur }}
                    </a> -->
                    <b-button role="tab" :variant="indexAssureur==index ? 'gradient-primary': 'outline-primary'"  class="nav-link" :class="indexAssureur==index ? 'active': ''" @click="indexAssureur=index">
                        <span v-html="produit.assureur.nom_com_assureur == 'ECA ASSURANCES' || produit.assureur.nom_com_assureur == 'SPVIE ASSURANCES'  ? produit.assureur.nom_com_assureur + ' <small>' + produit.produit_nom + '<small>' : produit.assureur.nom_com_assureur"></span>
                        
                    </b-button>
                </li>
            </ul>
        </div>
        <section v-for="(produit, index) in risque.produits" :key="index" v-show="canShowProduit(produit.id, null)">
            <b-row v-show="index==indexAssureur">
                <b-col lg="12">
                    <h4 class="text-primary font-weight-bolder my-2 ml-1">{{ produit.produit_nom }}</h4>
                </b-col>
                <b-col lg="9">
                    <b-card>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FeatherIcon" size="20" />DESCRIPTION</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <div v-if="produit.description" v-html="produit.description" class="text-justify"></div>
                             <b-alert v-else variant="primary" show>
                                <div class="alert-body">
                                    <span>Aucune description disponible</span>
                                </div>
                            </b-alert>
                        </b-card-body>
                    </b-card>
                    <b-card v-show="false">
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FilmIcon" size="20" />DIDACTICIEL</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                    </b-card>
                    <b-card>
                        <b-card-header class="flex-column align-items-start py-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FileIcon" size="20" />LES + PRODUIT</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            
                            <div v-if="produit.id == '18'">
                                <b-col md="12" >
                                    <p>
                                        <strong>Outre les garanties classiques (responsabilité civile, vol, incendie et dommage), FMA Assurances propose des garanties innovantes parmi lesquelles :</strong>
                                    </p>  
                                </b-col>
                            </div>
                        
                            <b-row v-if="produit.produitavantages.length > 0">
                                <b-col md="12">
                                   
                                    <b-list-group flush>
                                        <b-list-group-item v-for="avantage in produit.produitavantages" :key="avantage.id" v-html="avantage.avantage_produit">                                   
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-col>
                            </b-row>
                            <b-alert v-else variant="primary" show>
                                <div class="alert-body">
                                    <span>Aucun avantage disponible</span>
                                </div>
                            </b-alert>

                           

                        </b-card-body>
                    </b-card>
                    <b-card v-if="hasCodeProduit(produit.id) && produit.produit_ws_nom == 'TNS_SPHERIA'">
                        <b-card-header class="flex-column align-items-start py-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FileIcon" size="20" />DOCS DE FORMATION</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>

                        <b-card-body>
                            <b-row class="text-center">
                                <b-col md="12" class="mb-1 d-flex">
                                    <div class="mb-1 mr-2 float-left">
                                        <a href="/docsFormation/Presentation_Webinare_BUBBLE_IN_PERFORM_TNS.pdf" download>
                                            <b-img size="40" class="cursor-pointer" :src="require('@/assets/images/icons/doc.png')" />
                                        </a>
                                    </div>
                                    <b-card-text class="mb-0 text-left">
                                        <strong>Titre:</strong> Formation Perform' Santé TNS <br>
                                    </b-card-text>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                    <b-card>
                        <b-card-header class="flex-column align-items-start pt-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FileIcon" size="20" />DOCUMENTS À
                                    TÉLÉCHARGER</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="text-center" v-if="produit.document_assureur.length > 0">
                                <b-col md="12" class="mb-1 d-flex" v-for="doc in produit.document_assureur" :key="doc.id">
                                    <div class="mb-1 mr-2 float-left">
                                        <b-img size="40" class="cursor-pointer" :src="require('@/assets/images/icons/doc.png')" @click="downloadDocument(doc.document.path.base64, doc.document.nom_custom)" />
                                    </div>
                                    <b-card-text class="mb-0 text-left">
                                        <strong>Titre:</strong> {{ doc.document.nom_custom }} <br>
                                        <strong>Référence:</strong> {{ doc.reference }} <br>
                                        <!-- <strong>Date de creation:</strong> {{ doc.document.created_at | formatDate }} -->
                                    </b-card-text>
                                </b-col>
                            </b-row>
                            <b-alert v-else variant="primary" show>
                                <div class="alert-body">
                                    <span>Aucun document disponible</span>
                                </div>
                            </b-alert>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col lg="3">
                     <b-card>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="TargetIcon" size="20" />CIBLE</h4>
                            </b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <b-list-group v-if="produit.produit_cibles.length > 0" flush>
                                <b-list-group-item v-for="data in produit.produit_cibles" :key="data.id">
                                    <div v-html=data.label></div>

                                </b-list-group-item>
                            </b-list-group>
                            <b-alert v-else variant="primary" show>
                                <div class="alert-body">
                                    <span>Aucune cible disponible</span>
                                </div>
                            </b-alert>
                        </b-card-body>
                    </b-card>
                    <b-card v-if="produit.produitrecompenses.length > 0 ">
                        <b-card-header class="flex-column align-items-start pt-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="AwardIcon" size="20" />RÉCOMPENSES</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="text-center">
                                <b-col md="12" cols="12" class="mb-1" v-for="(recompense, index) in $_.orderBy(produit.produitrecompenses, ['id'], ['desc']) "
                                    :id="index" :key="index">
                                    <!-- <div class="mb-1 mr-2 float-left"> -->

                                    <b-img style = "width:80%;" size="20" :src="recompense.path.base64" class="img-fluid" />
                                    
                                    <!-- </div> -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </section>
        <!-- <div class="row mb-2">
            <div class="col">
                    <div class="d-flex">
                        <div class="position-relative mr-2">
                            <div class="position-relative mb-1">
                                <img :src="logo ? logo : produit.assureur.assureur_image"  alt="logo" id="logo" height="120" />
                            </div>
                            <h1 class="text-primary font-weight-bolder mr-2">{{ produit.produit_nom }}</h1>
                        </div>
                    </div>
            </div>
            <div class="col-auto">
                <b-button block variant="outline-primary" @click="backStep">
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span>REVENIR À LA LISTE</span>
                </b-button>
            </div>
        </div> -->
        <!-- <section>
            <b-row>
                <b-col lg="9">
                    <b-card>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FeatherIcon" size="20" />DESCRIPTION</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <div v-html="produit.description" class="text-justify"></div>
                        </b-card-body>
                    </b-card>
                    <b-card v-show="false">
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FilmIcon" size="20" />DIDACTICIEL</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                    </b-card>
                    <b-card>
                        <b-card-header class="flex-column align-items-start pt-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="FileIcon" size="20" />DOCUMENT À
                                    TÉLÉCHARGER</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="text-center">
                                <b-col md="12" class="mb-1 d-flex" v-for="doc in documents" :key="doc.id">
                                    <div class="mb-1 mr-2 float-left">
                                        <b-img size="40" :src="require('@/assets/images/icons/doc.png')" />
                                    </div>
                                    <b-card-text class="mb-0 text-left">
                                        <strong>Titre:</strong> {{ doc.document.nom_custom }} <br>
                                        <strong>Référence:</strong> {{ doc.reference }} <br>
                                        <strong>Date de creation:</strong> {{ doc.document.created_at | formatDate }}
                                    </b-card-text>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col lg="3">
                    <b-card>
                        <b-card-header class="flex-column align-items-start">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="TargetIcon" size="20" />CIBLE</h4>
                            </b-card-title>
                        </b-card-header>
                        <b-card-body>
                            <b-list-group>
                                <b-list-group-item v-for="data in cibles" :key="data.id">
                                    {{ data.label }}
                                </b-list-group-item>
                            </b-list-group>
                        </b-card-body>
                    </b-card>
                    <b-card>
                        <b-card-header class="flex-column align-items-start pt-0">
                            <b-card-title>
                                <h4 class="title-custom-wizard">
                                    <feather-icon class="box-icon-text" icon="AwardIcon" size="20" />RÉCOMPENSES</h4>
                            </b-card-title>
                            <b-card-text class="text-muted mt-25"></b-card-text>
                        </b-card-header>
                        <b-card-body>
                            <b-row class="text-center">
                                <b-col md="6" cols="12" class="mb-1" v-for="(recompense, index) in recompenses"
                                    :id="index" :key="index">
                                    <div class="mb-1 mr-2 float-left">
                                        <b-img size="20" :src="recompense.base64" class="img-fluid" />
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </section> -->
    </div>

</template>
<script>
import {
    mapGetters
} from "vuex";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
    heightTransition
} from '@core/mixins/ui/transition'
import {
    quillEditor
} from 'vue-quill-editor'
import BCardCode from '@core/components/b-card-code'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import moment from 'moment'
import {
    BAlert,
    BImg,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormTextarea
} from 'bootstrap-vue'
export default {
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BModal,
        BFormFile,
        BCardHeader,
        BCardTitle,
        vSelect,
        BFormSelect,
        BFormSelectOption,
        BCardCode,
        quillEditor,
        ValidationProvider,
        ValidationObserver,
        // ToastificationContent,
        BFormCheckbox,
        BListGroup,
        BListGroupItem,
        BCardText,
        BLink,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BButton,
        BFormTextarea,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAlert
    },
    directives: {
        'b-modal': VBModal,
    },
    mixins: [heightTransition],
    created: function () {    
        this.risque = this.$store.state.produitModule.risque.data;
        this.indexAssureur = this.$store.state.produitModule.risque.number ? this.$store.state.produitModule.risque.number : (((this.canShowProduit(29, null) && (this.risque.id == 4 || this.risque.id == 7))) && this.fromList) ? 1 : 0
        this.produits = this.risque.produits
        console.log('firsting')
    },
    data() {
        return {
            indexAssureur:0,
            produits: [],
            risque: {},
            base64regex: /^data:image\/([a-zA-Z]*);base64,([^\"]*)?$/,
            showLoading: false,
        }
    },
    props: {
        isCatalogue: {
            type: Boolean,
            default: false,
            required: false
        },
        fromList: {
            type: Boolean,
            default: false,
            required: false
        },
    },
    mounted() {
    },
    computed: {},
    beforeDestroy() {
        this.$store.commit("setProd", {
            prod: 'getProduits',
        });
    },
    methods: {
        isBase64(str) {
            if (str ==='' || str.trim() ===''){ return false; }
            try {
                return btoa(atob(str)) == str;
            } catch (err) {
                return false;
            }
        },
        backStep() {
            if(this.$route.params.back_to)
                this.$router.push({ name: this.$route.params.back_to})

            if(this.isCatalogue) {
                this.$store.commit("setStep", {step: this.fromList ? 'searchCatalogue' : 'AssureurDetail'});
                this.$store.commit("setTab", {tab: this.fromList ? 'produit' : 'assureur'});
            }
            else {
                this.$store.commit("setProd", {
                    prod: 'getProduits',
                });
            }
        },
        downloadDocument(base64, name) {
            if (base64) {
                const a = document.createElement('a')
                a.href = base64
                a.download = name
                a.click()
                this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
            } else {
                this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
            }
        },
    }
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
    margin-bottom: 20px;
}
.box-icon-text{
    margin-top: -4px;
    margin-right: 5px;
}
.edit-img {
    position: absolute;
    bottom: -5px;
    right: -15px;
    z-index: 6;
}
.nav-assurances .nav-link {
    border: 1px solid #4D25BC !important;
    min-width: 250px;
    padding: 15px 15px;
    margin-right: 7px;
    transition: .3s color;
}

.nav-assurances .nav-link::after {
    display: none;
}

.nav-assurances .nav-link.active {
    color: white;
    transition: .3s color;
}

.nav-assurances .nav-link:hover {
    transform: translateY(0px)!important;
}
</style>