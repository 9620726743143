<template>
    <div>
        <b-overlay :show="showLoading" no-wrap />
        <b-modal
        id="modal-display-siret-data"
        ref="modal-display-siret-data"
        hide-footer
        centered
        size="lg"
        title="Informations Complémentaires"
        >
            <b-card-body style="padding: 0 1.5rem 1.5rem;">
                <b-form
                class="px-2"
                @submit.prevent="handleSubmitCheckData(onSubmit)"
                @reset.prevent="resetForm"
                >

                <b-alert
                    variant="warning"
                    show
                    class="mb-2"
                >
                    <div class="alert-body">
                    <!-- <span>
                        Merci de renseigner les informations necessaires
                    </span> -->
                    </div>
                </b-alert>
                <b-form-group
                    label="Siret*"
                    label-for="siret"
                >
                    <b-form-input
                    id="siret"
                    v-model="siret"
                    trim
                    placeholder="Siret"

                    />
                    <b-form-invalid-feedback :state="!$v.siret.$error">
                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-row>
                    <b-col md="12">
                    <div class="demo-inline-spacing">
                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        block
                        variant="primary"
                        @click="addsiret"
                        >
                        Enregistrer
                        </b-button>
                    </div>
                    </b-col>
                </b-row>
                </b-form>
            </b-card-body>
        </b-modal>
        <div class="row mb-2">
            <div class="col">
                <h1 class="text-primary font-weight-bolder  ml-1" >MES FORMATIONS DDA</h1>
            </div>
            <div class="col-auto">
              <b-button
                block
                variant="outline-primary"
                @click="backStep"
              >
              <feather-icon 
               icon="ArrowLeftIcon"
               class="mr-50"
              />
                <span v-if="formationPage=='index'">REVENIR À LA LISTE</span>
                <span v-else>REVENIR ÀU CHOIX</span>
              </b-button>
            </div>
        </div>

        <section>
            <b-row v-if="formationPage=='index'">
                <b-col lg="12">
                    <b-card >
                        <b-card-body>
                            <h4 class="title-custom-wizard">
                                Avec BubbleIn et Planète CSCA RH, répondre à son obligation de formation continue est devenu simple !
                            </h4>
                        </b-card-body>
                        <b-card-body class="pt-0">
                            <b-row cols="12">
                                <b-col xl="4" class="d-none d-xl-block"> 
                                    <b-img
                                        fluid
                                        class="card-img-top"
                                        :src="require('@/assets/images/pages/form.png')"
                                    />
                                </b-col>
                                <b-col xl="8">
                                    <div>
                                        Que vous soyez adhérent PLANETE CSCA ou non, pour mieux vous accompagner dans votre obligation de formation continue de 15 heures minimum par an, 
                                        accédez à la plateforme de formation simple et efficace PLANETE CSCA RH : 
                                        composez un parcours de formation, attribuez-le à un collaborateur et validez… nous nous occupons du reste ! 
                                    </div>

                                    <b-row style="margin-top:40px" >
                                        <b-col md="6" class="mb-2">
                                            <b-button
                                            class="mr-2 mb-25"
                                            variant="outline-primary"
                                            :class="!choixAdherant ? 'btn btn-primary' : ''"
                                            @click="changeFormation('adherant')"
                                            >
                                                Je suis adhérent PLANETE CSCA
                                            </b-button>
                                        </b-col>
                                        <b-col md="6">
                                            <b-button
                                                variant="outline-primary"
                                                :class="choixAdherant ? 'btn btn-primary' : ''"
                                                @click="noChooseAdherant"
                                                >
                                                    Je ne suis pas adhérent PLANETE CSCA
                                            </b-button>
                                            <b-form-group label="" class="mt-2" v-if="choixAdherant">
                                                <b-form-radio-group
                                                :options="options"
                                                v-model="adherantModel"
                                                name="radios-stacked"
                                                stacked
                                                />
                                            </b-form-group>
                                            <b-card v-if="choixAdherant && adherantModel!==null"
                                                class="text-right"
                                                title=""
                                            >
                                                <b-button
                                                    variant="primary"
                                                    size="md"
                                                    @click="submitadhesion"
                                                >
                                                    Suivant
                                                </b-button>
                                            </b-card>
                                        </b-col>
                                    </b-row>
    
                                    <!-- <div class="demo-inline-spacing" style="margin-top:40px">
                                        <b-button
                                        class="mr-2 mb-25"
                                        variant="outline-primary"
                                        @click="clearFiles"
                                        >
                                          Je suis adhérent PLANETE CSCA
                                        </b-button>
                                        <b-button
                                        variant="outline-primary"
                                        @click="file = null"
                                        >
                                        Je ne suis pas adhérent PLANETE CSCA
                                        </b-button>
                                    </div> -->

                                    <div class="mt-2">
                                        <span style="font-size:14px">Pour plus d”informations sur la plateforme PLANETE CSCA RH, <span class="cursor-pointer" @click="informationRedirect"><u>Cliquez ici.</u></span> </span>
                                        
                                    </div>

                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-show="formationPage=='adherant'">
                <b-col lg="12">
                    <b-card >
                        <b-card-body class="flex-column align-items-start">
                            <h4 class="title-custom-wizard">
                                Je suis adhérent PLANETE CSCA, je renseigne mon numéro d'adhérent avant d'être redirigé vers la plateforme PLANETE CSCARH.
                            </h4>
                        </b-card-body>
                        <b-card-body class="mb-4 pt-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        label="MON NUMERO D’ADHERENT"
                                        label-for="numero_adherent"
                                    >
                                        <b-form-input
                                        id="numero_adherent"
                                        v-model="numeroAdherant"
                                        placeholder="Numéro d'adherant"
                                        />
                                        <b-form-invalid-feedback :state="!$v.numeroAdherant.$error">
                                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        label="SIRET"
                                        label-for="siret"
                                    >
                                        <b-form-input
                                        id="siret"
                                        v-model="siret"
                                        placeholder="SIRET"
                                        />
                                        <b-form-invalid-feedback :state="!$v.siret.$error">
                                        Veuillez renseigner le(s) champ(s) obligatoire(s)
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <b-button
                                        type="submit"
                                        variant="primary"
                                        class="mr-1"
                                        @click="redirectPlanete"
                                    >
                                        Suivant
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </section>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import BCardCode from '@core/components/b-card-code'
import {required} from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BFormInvalidFeedback,BImg,BOverlay,BRow,BCol,BTabs, BTab,BCardTitle, BCardText,BCard,BCardBody,BCardHeader,BButton,BFormRadioGroup,BFormGroup,BFormInput } from 'bootstrap-vue'
export default {
    components:{
        BFormInvalidFeedback,
        BImg,
        BOverlay,
        BRow,
        BCol,
        BCardCode,
        BCardText,
        BCardHeader,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BCardTitle,
        BButton,
        BFormRadioGroup,
        BFormGroup,
        BFormInput,
        ToastificationContent
    },
    created:function(){
    },
    data() {
        return {
            indexAssureur:0,
            assureurs:[{nom_assureur:'Harmonie'},{nom_assureur:'ECA'}],
            objetLocal:{
                risque:null
            },
            showLoading:false,
            isAdherant:null,
            options: [
                {
                  text: 'Je ne souhaite pas adhérer à PLANETE CSCA. Mon abonnement sera facturé 280 euros TTC / an. Si je suis éligible OPCO, mes formations et celles de mes collaborateurs seront prises en charge par mon OPCO.',
                  value: true
                },
                { 
                  text: 'Je souhaite adhérer à PLANETE CSCA. Mon abonnement sera facturé 180 euros TTC / an. Si je suis éligible OPCO, mes formations et celles de mes collaborateurs seront prises en charge par mon OPCO.',
                  value: false
                },
            ],
            choixAdherant:false,
            adherantModel:null,
            formationPage:'index',
            numeroAdherant:null,
            numeroAdherantOriginal:null,
            siret:  null,
            currentUser: JSON.parse(localStorage.getItem('userData')),
            siretLoca:null
        }
    }, 
    computed: {
        ...mapGetters(["getObjet"]),
    },
    watch: {
        getObjet: {
            handler: function(old, nld) {
                this.objetLocal = old
            },
            immediate: true
        },
        currentUser: {
            handler: function(old, nld) {
                this.numeroAdherant = old.courtier_user[0].courtier.numero_adherent_cscaRh ?? null
                this.siret = old.courtier_user[0].courtier.personne_morale.siret ?? null
            },
            immediate: true,
            deep: true
        },
        // adherantModel: {
        //     handler: function(old, nld) {
        //         let data={
        //             numero_adherent_cscaRh:null,
        //             isAdherant:old
        //         }
        //         this.$http.post(`courtier/generateCourtierToken`,data).then((r) => {
        //             window.open(r.data.data, '_blank');
        //         }).catch((err)=>{
        //             console.log(err)
        //         })
        //     },
        //     deep: true
        // }
    },
    validations: {
        numeroAdherant:  { required },
        siret:  { required },
    },
    methods: {
        backStep(){
            if(this.formationPage=='index'){
                this.showLoading=true
                this.$store.commit("setStep", {
                    step: 'searchCatalogue',
                });
                this.$store.commit("setTab", {tab: 'assureur'});
                this.showLoading=false
            }else{
                this.formationPage='index'
            }
        },
        submitadhesion(){
            if(this.siret=='' || this.siret==null){
                this.$refs['modal-display-siret-data'].show()
            }
            else{
                let data={
                    numero_adherent_cscaRh:null,
                    isAdherant:this.adherantModel,
                    siret: this.siret ? this.siret : null ,
                }
                this.$http.post(`courtier/generateCourtierToken`,data).then((r) => {
                    window.open(r.data.data, '_blank');
                }).catch((err)=>{
                    console.log(err)
                })
            }
        },
        addsiret(){
            this.$v.$reset();
            this.$v.$touch();
            if (this.$v.$error) {
                return 0;
            }
            this.$refs['modal-display-siret-data'].hide()
            this.submitadhesion()
        },
        changeFormation(val){
            this.choixAdherant=false
            this.formationPage=val
        },
        chooseAdherant(){
            this.choixAdherant=false
        },
        noChooseAdherant(){
            this.choixAdherant=true
        },
        redirectPlanete(){
            this.$v.$reset();
            this.$v.$touch();
            if (this.$v.$error) {
                return 0;
            }
            let data={
                numero_adherent_cscaRh:this.numeroAdherant,
                isAdherant:true,
                siret:this.siret,
            }
            if(this.numeroAdherantOriginal == null || this.numeroAdherantOriginal == '' ){
                this.$http.post(`courtier/generateCourtierToken`,data).then((r) => {
                window.open(r.data.data, '_blank');
                }).catch((err)=>{
                console.log(err)
                })
            }else{
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Un compte existe déjà avec ces identifiants, merci de vous connecter directement à la plateforme de PLANETE CSCA RH',
                    icon: 'EditIcon',
                    variant: 'danger',
                    },
                })
                return 
            }

        },
        informationRedirect(){
            window.open("https://planetecscarh.com/formation-dda/", '_blank');
        },
        getPersonneMoral(){
             if (this.currentUser.courtier_user[0].courtier.id) {
                this.$http
                .get(`prospect/getpersonneMoraleById/${this.currentUser.courtier_user[0].courtier.personne_morale.id}`)
                .then((res) => {
                    this.siret = res.data.data.siret
                    this.numeroAdherant = res.data.data.courtier.numero_adherent_cscaRh
                    this.numeroAdherantOriginal = res.data.data.courtier.numero_adherent_cscaRh
                })
                .catch((err) => {});
            } else {
                
            }
        }
    },
    mounted(){
        this.getPersonneMoral()
    }
}
</script>
<style>
.title-custom-wizard{
    color: #4d25bc !important ;
    margin-bottom: 20px;
}
.box-icon-text{
    margin-top: -4px;
    margin-right: 5px;
}
label.custom-control-label > span {
    font-size: 12px;
    font-weight: bolder;
}
label.custom-control-label  {
    margin-bottom: 10px;
}
</style>