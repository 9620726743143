<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <div class="row mb-2">
      <div class="col">
        <b-col class="md-12">
          <div class="d-flex">
            <div class="position-relative mr-3">
              <img
                src="../../../public/Weedoit.webp"
                alt="logo"
                id="logo"
                height="120"
              />
            </div>
          </div>
        </b-col>
      </div>
      <div class="col-auto">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="outline-primary"
          @click="backStep"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-50" />

          REVENIR À LA LISTE
        </b-button>
      </div>
    </div>
    <section>
      <b-row>
        <b-col lg="9">
          <b-card>
            <b-card-header class="flex-column align-items-start pb-1">
              <b-card-title>
                <h4 class="title-custom-wizard">
                  <feather-icon
                    class="box-icon-text"
                    icon="FeatherIcon"
                    size="20"
                  />DESCRIPTION
                </h4>
              </b-card-title>
              <b-card-text class="text-muted mt-25"></b-card-text>
            </b-card-header>
            <b-card-body>
              <div class="text-all-content text-justify">
                <div style="font-size: 14, 5px">
                  <div class="content-text">
                    Weedo IT est une agence digitale spécialisée à la
                    performance. Elle a pour mission d'optimiser le ROI des
                    actions marketing digital de ses clients.
                  </div>
                  <div class="content-text">
                    Son savoir-faire est composé en 5 pôles d’expertise :
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <img
                    src="../../../public/image-20230124-140940.png"
                    alt="poles"
                    id="poles"
                    height="450"
                  />
                </div>
                <div style="font-size: 14, 5px">
                  <div class="content-text">
                    Grâce à son expertise et à son savoir-faire Weedo IT
                    accompagne de manière personnalisée ses clients, en
                    analysant et en optimisant leur stratégie digitale. L’agence
                    met également tout en œuvre pour proposer aux professionnels
                    de l’assurance, les meilleures offres correspondantes à leur
                    trafic et adaptées à leur audience.
                  </div>
                  <div class="content-text">
                    Depuis sa création en 2004, Weedo IT est engagée auprès
                    d’organismes, de commissions nationales et de syndicats
                    professionnels reconnus pour s’améliorer continuellement et
                    répondre aux attentes de ses partenaires et clients. Elle
                    met tout en œuvre pour respecter les normes réglementaires
                    françaises et européennes, et comprendre les enjeux actuels
                    et futurs du marché en perpétuelle mutation.
                  </div>
                 
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="3">
          <div>
            <b-card>
              <b-card-header
                class="d-flex justify-content-start align-items-center pb-1"
              >
                <b-card-title>
                  <h4 class="title-custom-wizard">
                    <feather-icon
                      class="box-icon-text"
                      icon="UsersIcon"
                      size="20"
                    />INTERLOCUTEURS
                  </h4>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <!-- user suggestion  -->
                <div class="mb-2">
                  <b-avatar class="mr-50 mb-1" size="40" />
                  <div class="user-page-info">
                    <h6 class="mb-0">Equipes commerciales</h6>
                    <small class="text-muted d-block">
                      <tr>
                        <td>Téléphone</td>
                        <td style="padding: 0 10px">:</td>
                        <td>04 72 61 26 31</td>
                      </tr>
                      <!-- <tr><td>Fixe </td><td style="padding: 0 10px;">:</td><td>01 71 60 90 58</td></tr> -->
                      <tr>
                        <td>Email</td>
                        <td style="padding: 0 10px">:</td>
                        <td>
                          <a :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="showEmail('commercial@weedoit.fr')">
                              commercial@weedoit.fr
                          </a>
                        </td>
                      </tr>
                    
                    </small>
                  </div>
                </div>
              </b-card-body>
            </b-card>
            <b-card>
              <b-card-header
                class="d-flex justify-content-start align-items-center pb-1"
              >
                <b-card-title>
                  <h4 class="title-custom-wizard">
                    <feather-icon
                      class="box-icon-text"
                      icon="GiftIcon"
                      size="20"
                    />AVANTAGE UTILISATEURS BUBBLEIN
                  </h4>
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <!-- user suggestion  -->
                <div class="mb-2">
                  <div class="user-page-info">
                    <h6 class="mb-0">
                      Bénéficiez de 10% de réduction sur votre 1ere commande Weedo Market <a href="https://calendly.com/communication-24/partenariat-bubblein-weedo-market?month=2023-02" target="_blank">(Lien ici)</a></h6>
                    
                  </div>
                  <b-button v-if="currentUser.courtier_user[0].isPrincipal"   @click="sendMailToWeedoIt" :disabled="disable_request_demande_cnx"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class=" my-2 ">
        <span class="align-middle text-uppercase">Envoyer accès a Weedo IT</span>
      </b-button>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Interlocuteurs from "@/views/assureur/animation-commerciale/assureur/interlocuteurs/Interlocuteurs.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { heightTransition } from "@core/mixins/ui/transition";
import { quillEditor } from "vue-quill-editor";
import BCardCode from "@core/components/b-card-code";
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import InterlocuteursData from "@/shared/constants/interlocuteurs";
import {
  BImg,
  BOverlay,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BCard,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BAvatar,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
  BFormCheckboxGroup,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Ripple,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BOverlay,
    BRow,
    BCol,
    BModal,
    BAvatar,
    BFormFile,
    BCardHeader,
    BCardTitle,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BCardCode,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    // ToastificationContent,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    BMedia,
    BMediaAside,
    BFormCheckboxGroup,
    BMediaBody,
    Interlocuteurs,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  mixins: [heightTransition],
  created: function () {
    // this.getAllProduits()
    // this.$store.dispatch('getAllServices')
  },
  data() {
    return {
      isBoiteSync: false,
      showLoading: false,
      produitType: null,
      assureur: null,
      interlocuteurs: [],
      logo: null,
      edit: false,
      base64regex: /^data:image\/([a-zA-Z]*);base64,([^\"]*)?$/,
      required,
      email,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      disable_request_demande_cnx:true,
    };
  },
  props: {
    isCatalogue: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters({ services: "getServices", objectAssurance: "getObjet" }),
  },
  created(){
    this.checkSyncBoiteEmail()
    this.checkRequestAlreadySent();
    console.log(this.disable_request_demande_cnx);
  },
  methods: {
    checkSyncBoiteEmail() {
      this.isBoitEmailSync().then(response => {
        if(response)
          this.isBoiteSync = true
        else this.isBoiteSync = false
      })
    },
    showEmail (email, copierCarbon = null) {
      if(this.isBoiteSync) {
          this.$router.push({
            name: 'messageries',
            params: {
              operation: 'send',
              email,
              copierCarbon
            }
          })
      }
    },
    backStep() {
      this.showLoading = true;
      this.$store.commit("setFromList", false);
      this.$store.commit("setStep", {
        step: "searchCatalogue",
      });
      this.showLoading = false;
    },
    checkRequestAlreadySent(){
      this.$http.post(`courtier/checkDemandeCnxEnvoye/${this.currentUser.courtier_user[0].courtier_id}`).then((res)=>{
        console.log(res.data);
        if(res.data.data==1){
          this.disable_request_demande_cnx = true;
        }else{
          this.disable_request_demande_cnx = false;
        }
      })
    },
    sendMailToWeedoIt(){
      let formData = new FormData()
      formData.append('id',this.currentUser.courtier_user[0].courtier_id)
      formData.append('user_id',this.currentUser.courtier_user[0].user_id)
      formData.append('denom_com',this.currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial)
      formData.append('raison_sociale',this.currentUser.courtier_user[0].courtier.personne_morale.raison_sociale)
      this.$http.post(`courtier/demande-de-connexion-weedoit`,formData).then((res)=>{
        if(res.data.success){
          this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: res.data.message,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          // text: "Vous vous êtes connecté avec succès en tant qu'administrateur. Vous pouvez maintenant commencer à explorer!"
                        }
                      })
                      this.checkRequestAlreadySent();
        }else{
          this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: res.data.message,
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                          // text: "Vous vous êtes connecté avec succès en tant qu'administrateur. Vous pouvez maintenant commencer à explorer!"
                        }
                      })
        }
      })
    }
  },
};
</script>
<style>
.title-custom-wizard {
  color: #4d25bc !important ;
  margin-bottom: 5px;
}

.box-icon-text {
  margin-top: -4px;
  margin-right: 5px;
}
.edit-img {
  position: absolute;
  bottom: -5px;
  right: -15px;
  z-index: 6;
}
.content-text {
  margin: 10px 0;
}
.point {
  font-size: 50px;
}
.icon {
  width: 6px;
}
.text-all-content {
  font-size: 13.5px;
}
</style>
