<template>
    <div>
      <b-overlay :show="showLoading" no-wrap />
      <div class="row mb-2">
        <div class="col">
          <b-col class="md-12">
            <div class="d-flex">
              <div class="position-relative mr-3">
                <img
                  src="../../../public/Endya.png"
                  alt="logo"
                  id="logo"
                  height="120"
                />
              </div>
            </div>
          </b-col>
        </div>
        <div class="col-auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            @click="backStep"
          >
            <feather-icon icon="ArrowLeftIcon" class="mr-50" />
  
            REVENIR À LA LISTE
          </b-button>
        </div>
      </div>
      <section>
        <b-row>
          <b-col lg="9">
            <b-card>
              <b-card-header class="flex-column align-items-start pb-1">
                <b-card-title>
                  <h4 class="title-custom-wizard">
                    <feather-icon
                      class="box-icon-text"
                      icon="FeatherIcon"
                      size="20"
                    />DESCRIPTION
                  </h4>
                </b-card-title>
                <b-card-text class="text-muted mt-25"></b-card-text>
              </b-card-header>
              <b-card-body>
                <div class="text-all-content text-justify">
                  <div style="font-size: 14, 5px">
                    <div class="content-text">
                        <b>ENDYA est une association d’autorégulation du courtage récente et indépendante.</b> Elle a pour mission d’encadrer les professionnels de l’intermédiation en assurances et en opérations de banque et services de paiement, en toute indépendance, quel que soit leur statut (courtier, mandataire de courtier). La mission principale d’ENDYA est <b>d’accompagner ses adhérents</b> :
                    </div>
                    <div class="content-text">
                        <div class="content-text"><feather-icon icon="CircleIcon" class="icon mr-50 ml-2"/><b>Conformité</b> : Endya accompagne ses adhérents dans leur mise en conformité, afin d’exercer leur profession dans les meilleures conditions.</div>
                        <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/><b>Statistiques</b> : Elle réalise des enquêtes statistiques permettant une connaissance fine du marché et de ses modes d’exercice.</div>
                        <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/><b>Travail pédagogique</b> : Elle met à disposition des guides sur l’ensemble des aspects relatifs à l’accession à la profession/veille réglementaire.</div>                           
                        <div class="content-text ml-2"><feather-icon icon="CircleIcon" class="icon mr-50"/><b>Médiation</b> : Endya s’assure que ses membres proposent à leurs clients l’accès à un médiateur de la consommation agréé.</div>
                    </div>
                    <div class="content-text">
                      <b>Endya renouvelle sa plateforme d’adhésion !</b>
                      <p>Déjà adhérent Endya ? Vous avez reçu un mail de connexion de la part de votre association d'autorégulation afin d'accéder à votre espace adhérent et intégrer vos documents accessibles depuis le coffre-fort conformité BubbleIn.</p>
                      <b>Surveillez vos boites mails !</b>
                    </div>

                  </div>
                  
                  
                </div>
              </b-card-body>
            </b-card>
            <b-card>
              <b-card-header class="flex-column align-items-start py-0">
                <b-card-title><h4 class="title-custom-wizard"><feather-icon class="box-icon-text"  icon="FileIcon" size="20"/>DOCUMENTS À TÉLÉCHARGER</h4></b-card-title>
                <b-card-text class="text-muted mt-25"></b-card-text>
              </b-card-header>
              <b-card-body>
                <b-list-group flush>
                  <b-list-group-item button @click="downloadFile('20231006 - MA CONFORMITE IAS EN BREF.pdf')">
                    <b-img height="30" width="auto" :src="require('@/assets/images/icons/doc.png')" class="mr-1"/>20231006 - MA CONFORMITE IAS EN BREF
                  </b-list-group-item>
                  <b-list-group-item button @click="downloadFile('20231006 - MA CONFORMITE IOBSP EN BREF.pdf')">
                    <b-img height="30" width="auto" :src="require('@/assets/images/icons/doc.png')" class="mr-1"/>20231006 - MA CONFORMITE IOBSP EN BREF
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="3">
            <div>
              <b-card>
                <b-card-header
                  class="d-flex justify-content-start align-items-center pb-1 px-0"
                >
                  <b-card-title>
                    <h4 class="title-custom-wizard">
                      <feather-icon
                        class="box-icon-text"
                        icon="UsersIcon"
                        size="20"
                      />INTERLOCUTEURS
                    </h4>
                  </b-card-title>
                </b-card-header>
                <b-card-body class="px-0">
                  <!-- user suggestion  -->
                  <div class="mb-2">
                    <b-avatar class="mr-50 mb-1" size="40" />
                    <div class="user-page-info">
                      <h6 class="mb-0">Contact</h6>
                      <small class="text-muted d-block">
                        <tr>
                          <td>Téléphone</td>
                          <td style="padding: 0 10px">:</td>
                          <td>01 76 46 18 55</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td style="padding: 0 10px">:</td>
                          <td>
                            <a :style="isBoiteSync ? { 'text-decoration': 'underline' } : { 'text-decoration': 'initial', 'cursor': 'default' }" @click="showEmail('support@endya.fr')">
                                support@endya.fr
                            </a>
                          </td>
                        </tr>
                      
                      </small>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
              <b-card>
              <b-card-header
                class="d-flex justify-content-start align-items-center pb-1 px-0"
              >
                <b-card-title>
                  <h4 class="title-custom-wizard">
                    <feather-icon
                      class="box-icon-text"
                      icon="GiftIcon"
                      size="20"
                    />NOUVEAUX ADHÉRENTS ENDYA
                  </h4>
                </b-card-title>
              </b-card-header>
              <b-card-body class="px-0">
                <!-- user suggestion  -->
                <div class="mb-2">
                  <div class="user-page-info">
                    <h6 class="mb-0">
                        Vous souhaitez adhérer, veuillez utiliser les documents que vous avez téléchargé dans votre coffre-fort BubbleIn.</h6>
                    
                  </div>
                <b-row>
                  <b-col class="text-center">
                    <b-button   @click="storeCourtierAdheration('adherer')"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class=" my-2 ">
                     <span class="align-middle text-uppercase">Adhérer</span> 
                    </b-button>
                  </b-col>
                  <!-- <b-col>
                    <b-button   @click="storeCourtierAdheration('renouveler')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="md" class=" my-2 ">
                    <span class="align-middle text-uppercase">RENOUVELER</span>
                  </b-button>
                  </b-col>                 -->
                </b-row>    
                </div>
              </b-card-body>
            </b-card>
            </div>
          </b-col>
        </b-row>
      </section>
    </div>
  </template>
  
  <script>
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";
  import { heightTransition } from "@core/mixins/ui/transition";
  import Ripple from "vue-ripple-directive";
  import {
    BImg,
    BOverlay,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BModal,
    VBModal,
    BAvatar,
    BCardBody,
    BCardHeader,
    BCardTitle,
  } from "bootstrap-vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      Ripple,
      BImg,
      BOverlay,
      BRow,
      BCol,
      BModal,
      BAvatar,
      BCardHeader,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardText,
      BLink,
      BCard,
      BCardBody,
      BTabs,
      BTab,
      BButton,     
    },
    directives: {
      Ripple,
      "b-modal": VBModal,
    },
    mixins: [heightTransition],
    data() {
      return {
        showLoading: false,
        currentUser: JSON.parse(localStorage.getItem('userData')),
      isBoiteSync: false,
      disableAdheratioBtn:false
      };
    },
    created(){
        this.checkRequestAlreadySent();
        this.checkSyncBoiteEmail();
    },
    methods: {
      checkRequestAlreadySent(){
        this.$http.get(`association_autoregulation/check-courtier-adheration-endya/1/${this.currentUser.courtier_user[0].courtier_id}`).then((res)=>{
          if(res.data.data){
            this.disableAdheratioBtn = true;
          }else{
            this.disableAdheratioBtn = false;
          }
        })
      },
      storeCourtierAdheration(actionType){
            this.showLoading = true;
            this.$http.get(`association_autoregulation/courtier-adheration-endya/1/${this.currentUser.courtier_user[0].courtier_id}/${this.currentUser.courtier_user[0].user_id}/${actionType}`).then((res)=>{
                if(res.data.success){
                    this.disableAdheratioBtn = true;

                    if(actionType == 'adherer'){
                      window.open('https://app.endya.fr/adhesion/siren', '_blank');
                    }else{
                      window.open('https://app.endya.fr/', '_blank');
                    }      
                
                }else{
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: res.data.message,
                          icon: 'CoffeeIcon',
                          variant: 'danger',
                        }
                      })
                }
            }).finally(()=>{
                this.showLoading = false;
            })
        },
      backStep() {
        this.showLoading = true;
        this.$store.commit("setFromList", false);
        this.$store.commit("setStep", {
          step: "searchCatalogue",
        });
        this.showLoading = false;
      },
      checkSyncBoiteEmail() {
      this.isBoitEmailSync().then(response => {
        if(response)
          this.isBoiteSync = true
        else this.isBoiteSync = false
      })
    },
      showEmail (email, copierCarbon = null) {
      if(this.isBoiteSync) {
          this.$router.push({
            name: 'messageries',
            params: {
              operation: 'send',
              email,
              copierCarbon
            }
          })
      }
    },
      downloadFile(file) {
        const a = document.createElement('a');
        a.href = '/documents/Endya/' + file;
        a.setAttribute('download', file);
        a.click();
      }
    },
  };
  </script>
  <style>
  .title-custom-wizard {
    color: #4d25bc !important ;
    margin-bottom: 5px;
  }
  
  .box-icon-text {
    margin-top: -4px;
    margin-right: 5px;
  }
  .edit-img {
    position: absolute;
    bottom: -5px;
    right: -15px;
    z-index: 6;
  }
  .content-text {
    margin: 10px 0;
  }
  .point {
    font-size: 50px;
  }
  .icon {
    width: 6px;
  }
  .text-all-content {
    font-size: 13.5px;
  }
  </style>
  